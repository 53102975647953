import React, {useState} from "react"
import {graphql, useStaticQuery} from 'gatsby';
import axios from "axios"
import {
  Field,
  FieldLabel,
  FieldBody,
  Control,
  ModalBackground,
  ModalContent,
  ModalClose,
  Column, Columns
} from "bloomer";
import {
  ContModal,
  LabelBloomer,
  ContFormContacto, ContMessage, GlobalStylesFormContact
} from "./stylesFormContact";
import ButtonGuzbarraf from "../UI/Button";
import InputTextGuzbarraf from "../UI/InputText";
import TextAreaGuzbarraf from "../UI/TextArea";


const FormContactComp = () => {
  const { site } = useStaticQuery(
    graphql`
      query ContactFormSiteMetaData{
        site {
          siteMetadata {
            env
            siteUrl
          }
        }
      }
    `
  );

  //console.log('site => ', site);
  const { env, siteUrl } = site.siteMetadata;

  const bolHorizontal = false;
  const [showMessage, setShowMessage] = useState(false);

  const handleSendFormContact = () => {
    let url = `${siteUrl}/send-email/?mod=email`;

    if(env === 'development'){
      //url = 'http://localhost/Georgina-Quintana/Georgina-Quintana-Email/?mod=email';
      url = 'http://localhost/Email/?mod=email';
    }

    let txtNombre = document.querySelector('input[name="txtNombre"]').value;
    let txtEmail = document.querySelector('input[name="txtEmail"]').value;
    let txtTelefono = document.querySelector('input[name="txtTelefono"]').value;
    let txtMensaje = document.querySelector('textarea[name="txtMensaje"]').value;

    //console.log('formData => ', formData);
    //console.log('txtNombre => ', document.querySelector('input[name="txtNombre"]').value);

    const params = new URLSearchParams();
    params.append('txtNombre', txtNombre);
    params.append('txtEmail', txtEmail);
    params.append('txtTelefono', txtTelefono);
    params.append('txtMensaje', txtMensaje);

    axios({
      method: 'post',
      url: url,
      data: params
    }).then(function (response) {
      //console.log(response);
      //console.log(response.data);

      //let success = response.data.success;
      let message = response.data.data.message;

      document.getElementById('msg-content').innerHTML = message;
      setShowMessage(true);

    })
    .catch(function (error) {
      //console.log(error);
      document.getElementById('msg-content').innerHTML = error;
      setShowMessage(true);
    });

  }

  const handleCloseMessage = () => {
    //console.log('handleCloseMessage');
    setShowMessage(false);
  }

  return(
    <ContFormContacto>

      <GlobalStylesFormContact/>

      <form method='POST' id={'frmContact'} name={'frmContact'} action='#'>
        <Columns className="columns-responsive full-height">

          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal={false}>
                <LabelBloomer>Nombre:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <InputTextGuzbarraf
                    className={''}
                    type="text"
                    id={'txtNombre'}
                    name={'txtNombre'}
                    placeholder=''
                  />
                </Control>
              </FieldBody>
            </Field>

            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal>
                <LabelBloomer>Email:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <InputTextGuzbarraf
                    className={'input-contact'}
                    type="email"
                    id={'txtEmail'}
                    name={'txtEmail'}
                    placeholder=''
                  />
                </Control>
              </FieldBody>
            </Field>

            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal>
                <LabelBloomer>Teléfono:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <InputTextGuzbarraf
                    className={'input-contact'}
                    type="phone"
                    id={'txtTelefono'}
                    name={'txtTelefono'}
                    placeholder=''
                  />
                </Control>
              </FieldBody>
            </Field>
          </Column>

          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal={false}>
                <LabelBloomer>Mensaje:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <TextAreaGuzbarraf
                    className={''}
                    id={'txtMensaje'}
                    name={'txtMensaje'}
                    placeholder={''}
                  />
                </Control>
              </FieldBody>
            </Field>

            <Field isHorizontal={bolHorizontal}>
              <FieldLabel /> {/* empty for spacing */}
              <FieldBody>
                <Field>
                  <Control className={'align-button'}>
                    <ButtonGuzbarraf
                      text={'ENVIAR'}
                      className={'btnSecondary'}
                      onClick={() => handleSendFormContact()}
                    />
                  </Control>
                </Field>
              </FieldBody>
            </Field>
          </Column>

        </Columns>
      </form>

      <ContModal isActive={showMessage}>
        <ModalBackground />
        <ModalContent>
          <ContMessage id="msg-content">&nbsp;</ContMessage>
        </ModalContent>
        <ModalClose onClick={() => handleCloseMessage()} />
      </ContModal>

    </ContFormContacto>
  )
}

export default FormContactComp
