import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {GlobalColors} from '../../styles/GlobalStyles';

export const ContRedes = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  @media(min-width: 300px) and (max-width: 767px){
    justify-content: flex-start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    justify-content: flex-start;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContRedesIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 30px;
    margin-left: 0;
    margin-right: 15px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: flex-start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 30px;
    margin-left: 0;
    margin-right: 15px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: flex-start;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`

export const ALinkFacebook = styled.a`
  width: 100%;
  height: 100%;
  color: ${GlobalColors.colorLight};

  &:hover{
    color: ${GlobalColors.colorTertiary} !important;
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    
  }
  @media(min-width: 769px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ALinkTwitter = styled.a`
  width: 100%;
  height: 100%;
  
  @media(min-width: 300px) and (max-width: 768px){
    
  }
`
export const ALinkInstagram = styled.a`
  width: 100%;
  height: 100%;
  color: ${GlobalColors.colorLight};
  
  &:hover{
    color: ${GlobalColors.colorTertiary} !important;
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    
  }
`
export const ALinkLinkedin = styled.a`
  width: 100%;
  height: 100%;
  
  @media(min-width: 300px) and (max-width: 768px){
    
  }
`
export const FontAwesomeIconGuzbarraf = styled(FontAwesomeIcon)`
  width: 30px !important;
  height: 30px !important;
`
