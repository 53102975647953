import styled, { createGlobalStyle }  from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const GlobalStyleContact = createGlobalStyle`
  .page-contacto{
    position: relative;
    margin: 0 auto;
    color: ${GlobalColors.colorPrimary};
  }
  .input-contact{
    font-family: ${GlobalFonts.fontRegular};
    color: ${GlobalColors.colorPrimary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
  @media(min-width: 1408px){
    .page-contacto{
      width: 80%;
    }
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
`
export const ContContacto = styled.div`
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 2px solid #FFFFFF;
`
export const ContInfoContacto = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
export const InfoContacto = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
  
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorLight};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 16px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 18px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 24px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 24px;
  }
  @media(min-width: 1920px){
    font-size: 24px;
  }
`
export const TituloContacto = styled.div`
  margin-bottom: 25px;
  
  font-family: '${GlobalFonts.fontRegular}';
  text-align: center;
  color: ${GlobalColors.colorLight};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 36px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 38px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 40px;
  }
  @media(min-width: 1408px){
    font-size: 40px;
  }
`
export const AtteContacto = styled.div`
  font-family: 'NunitoSansLight';
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 22px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 26px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 28px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 28px;
  }
  @media(min-width: 1408px){
    font-size: 32px;
  }
`
export const NombreContacto = styled.div`
  font-family: 'TravelingTypewriter';
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 24px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 28px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 30px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 30px;
  }
  @media(min-width: 1408px){
    font-size: 34px;
  }
`
export const ContImgContact = styled.div`
  position: relative;
  margin: 0 auto;
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > img{
    width: 100%;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 50%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 50%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 50%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 50%;
  }
  @media(min-width: 1408px){
    width: 50%;
  }
`

