import styled from 'styled-components'
import {GlobalFonts, GlobalColors, GlobalBackgroundColors} from "../../styles/GlobalStyles";
import BackFooter from '../../images/back-footer.svg';
import {Link} from "gatsby";

export const ContFooter = styled.div`
  position: relative;
  padding-top: 50px;
  
  width: 100%;
  height: auto;
  
  background-color: ${GlobalBackgroundColors.bgTertiary};
  background-image: url(${BackFooter});
  background-position: bottom right;
  background-repeat: no-repeat;
`
export const ContLogo = styled.div`
  position: relative;
  /*margin-bottom: 25px;*/
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 200px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 220px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 220px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 220px;
  }
  @media(min-width: 1408px){
    width: 280px;    
  }
`
export const ContContact = styled.div`
  position: relative;
  
  height: 40px;
 
  & > div{
    float: left;
    margin-right: 15px;
  }
  & > div:nth-child(2){
    margin-top: -5px;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    left: 45px;
    width: 80%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    left: 60px;
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    left: 60px;
    width: 80%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    left: 80px;
    width: 60%;
  }
  @media(min-width: 1408px){
    left: 80px;
    width: 60%;
  }
`
export const IcoContact = styled.div`
  position: relative;
`
export const TextContact = styled.div`
  position: relative;
  
  font-family: ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorPrimary};
  
  & > a{
    color: ${GlobalColors.colorPrimary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const TitleSection = styled.div`
  margin-bottom: 50px;
  width: 200px;
  height: 50px;
  font: 20px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorLinkHover};
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 35px;
    margin-bottom: 10px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 35px;
    margin-bottom: 10px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ListSection = styled.ul`
  display: flex;
  flex-direction: row;
  font: 20px ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorLight};
  line-height: 30px;
  list-style: none;
  
  &>li{
    margin-right: 15px;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    flex-direction: column; 
  }
  @media(min-width: 768px) and (max-width: 1023px){
  
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const LinkSection = styled(Link)`
  font: 20px ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorLight};
  
  & :hover{
    color: ${GlobalColors.colorTertiary};
  }
`
export const ContRedes = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  @media(min-width: 300px) and (max-width: 767px){
    justify-content: flex-start;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    justify-content: flex-start;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const FooterDev = styled.div`
  position: relative;
  margin-top: 50px;
  
  width: 100%;
  min-height: 50px;
  height: auto;
  
  /*background-color: ${GlobalBackgroundColors.bgTertiary};*/
`
export const TextoDev = styled.div`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  
  font: 18px ${GlobalFonts.fontLight};
  color: ${GlobalColors.colorTertiary};
  
  @media(min-width: 300px) and (max-width: 767px){
    text-align: center;
    font-size: 13px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    text-align: center;
    font-size: 13px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
