import { createGlobalStyle } from 'styled-components'

import RobotoRegularEot from '../fonts/RobotoRegular.eot';
import RobotoRegularTtf from '../fonts/RobotoRegular.ttf';
import RobotoRegularWoff from '../fonts/RobotoRegular.woff';
import RobotoRegularWoff2 from '../fonts/RobotoRegular.woff2';
import RobotoRegularSvg from '../fonts/RobotoRegular.svg';

import RobotoItalicEot from '../fonts/RobotoItalic.eot';
import RobotoItalicTtf from '../fonts/RobotoItalic.ttf';
import RobotoItalicWoff from '../fonts/RobotoItalic.woff';
import RobotoItalicWoff2 from '../fonts/RobotoItalic.woff2';
import RobotoItalicSvg from '../fonts/RobotoItalic.svg';

import RobotoBoldEot from '../fonts/RobotoBold.eot';
import RobotoBoldTtf from '../fonts/RobotoBold.ttf';
import RobotoBoldWoff from '../fonts/RobotoBold.woff';
import RobotoBoldWoff2 from '../fonts/RobotoBold.woff2';
import RobotoBoldSvg from '../fonts/RobotoBold.svg';

export const GlobalFonts = createGlobalStyle`
  
  @font-face {
    font-family: 'RobotoRegular';
    src: url(${RobotoRegularEot});
    src: local('RobotoRegular'), local('RobotoRegular'),
        url(${RobotoRegularWoff2}) format('woff2'),
        url(${RobotoRegularWoff}) format('woff'),
        url(${RobotoRegularTtf}) format('truetype'),
        url('${RobotoRegularSvg}#RobotoRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'RobotoItalic';
    src: url(${RobotoItalicEot});
    src: local('RobotoItalic'), local('RobotoItalic'),
        url(${RobotoItalicWoff2}) format('woff2'),
        url(${RobotoItalicWoff}) format('woff'),
        url(${RobotoItalicTtf}) format('truetype'),
        url('${RobotoItalicSvg}#RobotoItalic') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'RobotoMedium';
    src: url(${RobotoBoldEot});
    src: local('RobotoBold'), local('RobotoBold'),
        url(${RobotoBoldWoff2}) format('woff2'),
        url(${RobotoBoldWoff}) format('woff'),
        url(${RobotoBoldTtf}) format('truetype'),
        url('${RobotoBoldSvg}#RobotoBold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'RobotoBold';
    src: url(${RobotoBoldEot});
    src: local('RobotoBold'), local('RobotoBold'),
        url(${RobotoBoldWoff2}) format('woff2'),
        url(${RobotoBoldWoff}) format('woff'),
        url(${RobotoBoldTtf}) format('truetype'),
        url('${RobotoBoldSvg}#RobotoBold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`
