import styled from 'styled-components'

export const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const UlMenu = styled.ul`
  width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const LiItemMenu = styled.li`
  min-width: 100px;
  text-align: center;
`
