import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {ContRedes, ContRedesIcon, ALinkFacebook, ALinkInstagram, FontAwesomeIconGuzbarraf} from './stylesRedesSVG';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const RedesSVG = props => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            linkFacebook
            linkInstagram
          }
        }
      }
    `
  )

  const linkFacebook = site.siteMetadata.linkFacebook;
  const linkInstagram = site.siteMetadata.linkInstagram;

  return(
    <ContRedes>
      <ContRedesIcon>
        <ALinkFacebook href={linkFacebook} target="_blank">
          <FontAwesomeIconGuzbarraf icon={faFacebook} />
        </ALinkFacebook>
      </ContRedesIcon>
      <ContRedesIcon>
        <ALinkInstagram href={linkInstagram} target="_blank">
          <FontAwesomeIconGuzbarraf icon={faInstagram} />
        </ALinkInstagram>
      </ContRedesIcon>
    </ContRedes>
  )
}
