import styled from 'styled-components'

export const LinkGuzbarraf = styled.a`
  /* Display & Box Model */
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  /* Typografhy */
  text-decoration: none;
  color: ${props => props.color};
  
  &:hover{
    color: ${props => props.color};
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
`
export const Svg = styled.svg`
  position: relative;

  display: flex;
  height: auto;
  align-items: flex-end;
  
  @media(min-width: 300px) and (max-width: 768px){
    width: 120px;
    height: 35px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    width: 120px;
    height: 35px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 120px;
    height: 35px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 120px;
    height: 35px;
  }
  @media(min-width: 1408px){
    width: 120px;
    height: 35px;
  }
`
