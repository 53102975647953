import * as React from "react"
import ItemMenuDesk from "./itemMenuDesk";
import {NavMenu, UlMenu, LiItemMenu} from "./styles";

const MenuDesk = ({itemsMenuDesk}) => {
  /*
  console.log('items => ', items);
  console.log('nodes => ', items[0].itemMenu);
  */

  //console.log('pathname ->', pathname);

  return (
    <NavMenu>
      <UlMenu>
        {
          itemsMenuDesk.map((item, index) => {
            return(
              <LiItemMenu key={`item_menu_${index}`}>
                <ItemMenuDesk
                  itemText={item.itemMenu}
                  linkTo={item.linkTo}
                  className={item.className}
                  bolHash={item.bolHash}
                  pathname={item.pathname}
                />
              </LiItemMenu>
            )
          })
        }
      </UlMenu>
    </NavMenu>
  )
}

export default MenuDesk
