import React, {  Fragment, useEffect } from 'react';
import { Columns, Column } from 'bloomer';
import {LogoPrimary} from "../Logo/logoPrimary";
import { IcoMenu } from "./ico-menu";
import { IcoCloseMenu } from "./ico-close-menu";
//import {GlobalColors} from "../../styles/GlobalStyles";
import { ContLogo } from "../Header/styles";
import {GlobalStyleNavFullScreen, ContainerNavFullScreen, Nav, Link} from "./styles";
//import ItemMenuDesk from '../Menu/itemMenuDesk';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const NavFullScreenOverlay = ({nameColor}) => {

  const fnGoToElement = (el) => {
    console.log('el =>', el);
    setTimeout(function(){
      scrollTo(`#${el}`);
    }, 50);
  }

  let handleMenuFullScreen = function(bolShowMenuResponsive, linkTo = '') {
    console.log('handleMenuFullScreen => ', bolShowMenuResponsive);
    let el;
    let cls;

    setTimeout(
      function() {
        el = document.getElementById('nav-fullscreen');
        el.classList.remove("nav-fullscreen-show");
        el.classList.remove("nav-fullscreen-hidden");

        cls = bolShowMenuResponsive ? "nav-fullscreen-show" : "nav-fullscreen-hidden";
        el.classList.add(cls);

        bolShowMenuResponsive ? document.getElementById("nav-fullscreen").style.height = "100%" : document.getElementById("nav-fullscreen").style.height = "0%";

        if(linkTo !== ''){
          fnGoToElement(linkTo);
        }
      },
      100
    );
  }

  useEffect(() => {
    function handleResize() {
      if(window.innerWidth >= 1024){
        document.getElementById("nav-fullscreen").style.height = "0%";
      }
      //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
  });

  return (
    <Fragment>
      <GlobalStyleNavFullScreen/>

      <IcoMenu
        onClick={() => handleMenuFullScreen(true)}
      />

      <div id="nav-fullscreen" className={`nav-fullscreen-hidden`}>
        <ContainerNavFullScreen className="cont-nav-fullscreen">
          <Columns className="columns-responsive">
            <Column className="flex-center" isSize={{mobile: 12, tablet: 12, desktop: 0, widescreen: 0 }}>
              <ContLogo>
                <LogoPrimary/>
              </ContLogo>

              <IcoCloseMenu
                onClick={() => handleMenuFullScreen(false)}
              />

            </Column>
          </Columns>
        </ContainerNavFullScreen>
        <ContainerNavFullScreen className="cont-nav-fullscreen">
          <Columns className="columns-responsive">
            <Column isSize={{mobile: 6, tablet: 6, desktop: 6, widescreen: 6 }}>
              <Nav>
                <Link
                  to={'/#inicio'}
                  className={'tertiary-item'}
                  onClick={() => handleMenuFullScreen(false, 'inicio')}
                >
                  Inicio
                </Link>
                <Link
                  to={'/#nosotros'}
                  className={'tertiary-item'}
                  onClick={() => handleMenuFullScreen(false, 'nosotros')}
                >
                  Nosotros
                </Link>
                <Link
                  to={'/#desarrollos'}
                  className={'tertiary-item'}
                  onClick={() => handleMenuFullScreen(false, 'desarrollos')}
                >
                  Desarrollos
                </Link>
                <Link
                  to={'/#contacto'}
                  className={'tertiary-item'}
                  onClick={() => handleMenuFullScreen(false, 'contacto')}
                >
                  Contacto
                </Link>
              </Nav>
            </Column>
            <Column isSize={{mobile: 6, tablet: 6, desktop: 6, widescreen: 6 }}>

            </Column>
          </Columns>
        </ContainerNavFullScreen>
      </div>
    </Fragment>
  )
}
