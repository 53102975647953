import styled, {createGlobalStyle} from 'styled-components';
import {Button, Label, Modal} from "bloomer";
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStylesFormContact = createGlobalStyle`
  .align-button{
    text-align: left;
  }
`
export const ContFormContacto = styled.div`
  position: relative;
  width: 100%;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 auto;
    /*width: 100%;*/
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 0 auto;
    /*width: calc(90% - 50px);*/
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin: 0 auto;
    /*width: 80%;*/
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin: 0 auto;
    /*width: 90%;*/
  }
  @media(min-width: 1408px){
    margin: 0 auto;
    /*width: 75%;*/
  }
`
export const LabelBloomer = styled(Label)`
  margin-bottom: 5px;
    
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorLight};
  text-align: left;
`
export const BtnSendBloomer = styled(Button)`
  font-family: ${GlobalFonts.fontMedium};
  background-color: ${GlobalBackgroundColors.bgPrimary};
  color: ${GlobalColors.colorSecondary};
`
export const ContModal = styled(Modal)`
  z-index: 9999;
`

export const ContMessage = styled.div`
  padding: 25px;
  font: 20px ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorSecondary};
`
