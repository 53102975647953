import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {ContRedes, ContRedesIcon, ALinkFacebook, ALinkInstagram} from './styles';
import {useSiteMetadata} from '../../hooks/use-site-metadata';

export const Redes = props => {
  const { linkFacebook, linkInstagram } = useSiteMetadata();

  return(
    <ContRedes>
      <ContRedesIcon>
        <ALinkFacebook href={linkFacebook} target="_blank">
          <FontAwesomeIcon icon={faFacebookF}/>
        </ALinkFacebook>
      </ContRedesIcon>
      <ContRedesIcon>
        <ALinkInstagram href={linkInstagram} target="_blank">
          <FontAwesomeIcon icon={faInstagram}/>
        </ALinkInstagram>
      </ContRedesIcon>
    </ContRedes>
  )
}


