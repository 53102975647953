import React from 'react';
import {ButtonRGB, GlobalStyleBtn} from './styles';
import {GlobalBackgroundColors} from '../../../styles/GlobalStyles';

const ButtonGuzbarraf = ({onClick, text, className, bgNormal = GlobalBackgroundColors.bgTertiary, bgHover = GlobalBackgroundColors.bgSecondary}) => {

  //console.log('bgNormal -> ', bgNormal);
  //console.log('bgHover -> ', bgHover);

  return (
    <ButtonRGB
      onClick={onClick}
      className={className}
      bgnormal={bgNormal}
      bghover={bgHover}
    >
      <GlobalStyleBtn/>
      {text}
    </ButtonRGB>
  )
}

export default ButtonGuzbarraf
