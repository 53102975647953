import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import {GlobalStyle} from '../../styles/GlobalStyles';
import {
  GlobalStyleContact,
  ContContacto, ContInfoContacto, TituloContacto, InfoContacto,
} from './styles';
import FormContactComp from './formContact';

const ContactoComp = () => {

  return (
    <ContContacto id={'contacto'}>
      <Container className='full-height'>
        <div className='cont-page page-contacto'>
          <GlobalStyle/>
          <GlobalStyleContact/>
          <TituloContacto>Contacto</TituloContacto>
          <Columns className="columns-responsive full-height">

            <Column isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
              <ContInfoContacto>
                <InfoContacto>
                  Estás interesado en uno de nuestros desarrollos… escríbenos.
                </InfoContacto>
              </ContInfoContacto>
            </Column>
            <Column isSize={{mobile: 12, tablet: 12, desktop: 8, widescreen: 8}}>
              <FormContactComp/>
            </Column>

          </Columns>
        </div>
      </Container>
    </ContContacto>
  );
};

export default ContactoComp;
