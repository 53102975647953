import styled from 'styled-components'
import {GlobalColors} from '../../styles/GlobalStyles';

export const ContRedes = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  @media(min-width: 300px) and (max-width: 767px){
    display: none;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    display: none;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    display: flex;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    display: flex;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    display: flex;
  }
  @media(min-width: 1920px){
    display: flex;
  }
`

export const ContRedesIcon = styled.div`
  width: 30px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 768px){
    width: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const ALinkFacebook = styled.a`
  font-size: 20px;
  color: #2D64E1;

  &:hover {
    color: ${GlobalColors.colorLinkHover};
  }
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`
export const ALinkTwitter = styled.a`
  font-size: 20px;
  color: #FFFFFF;

  &:hover {
    color: #82CCDD;
  }
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
`
export const ALinkInstagram = styled.a`
  font-size: 20px;
  color: #C13584;

  &:hover {
    color: ${GlobalColors.colorLinkHover};
  }
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
`
export const ALinkLinkedin = styled.a`
  font-size: 20px;
  color: #FFFFFF;

  &:hover {
    color: #82CCDD;
  }
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
`
