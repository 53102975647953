import {createGlobalStyle} from 'styled-components'

export const GlobalStyleLayout = createGlobalStyle`
  .layout{
    /*height: calc(100vh - 80px);*/
    /*height: 100vh;*/
    height: auto;
    /*contain: content;*/
    @media(min-width: 1024px){
      /*contain: content;*/
    }
  }
  .wrapper-pages{
    /*position: relative;*/
    position: relative;
    padding-top: 93px;
    /*height: calc(100vh - 95px);*/
    height: auto;
    bottom: 0;
    z-index: 999;
    
    @media(min-width: 1024px) and (max-width: 1999px){
      /*height: calc(100vh - 95px);*/
      height: auto;
    }
  }
`
