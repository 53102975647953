import { createGlobalStyle }  from 'styled-components'
import {GlobalBackgroundColors} from "../../styles/GlobalStyles";

export const GlobalStyle = createGlobalStyle`
  .page-home{
    height: auto;
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .full-height{
    height: 100%;
  }
`
