import styled, { createGlobalStyle } from 'styled-components';
import {Button} from 'bloomer'
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../../styles/GlobalStyles";

export const GlobalStyleBtn = createGlobalStyle`
  .btnPrimary{
    background-color: ${GlobalBackgroundColors.bgTertiary};
    border: 1px solid ${GlobalColors.colorBorder};
    color: ${GlobalColors.colorLight};
    
    &:hover{
      background-color: ${GlobalColors.colorTertiary};
      border: 1px solid ${GlobalColors.colorBorder};
      color: ${GlobalColors.colorMenuSecondary};
    }
  }
  .btnSecondary{
    background-color: ${GlobalBackgroundColors.bgPrimary};
    border: 1px solid ${GlobalColors.colorBorder};
    color: ${GlobalColors.colorMenuSecondary};
    
    &:hover{
      background-color: ${GlobalColors.colorTertiary};
      border: 1px solid ${GlobalColors.colorBorder};
      color: ${GlobalColors.colorMenuSecondary};
    }
  }
`

export const ButtonRGB = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
  
  font: 18px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorLink};
  
  border-radius: 3px;
  background-color: ${props => props.bgnormal || GlobalBackgroundColors.bgTertiary};
  border: 1px solid ${GlobalColors.colorTertiary};
  
  &:hover{
    color: ${GlobalColors.colorLinkHover};
    background-color: ${props => props.bghover || GlobalBackgroundColors.bgSecondary};
    border: 1px solid ${GlobalColors.bgTertiary};
  }
`
