import styled, {createGlobalStyle} from 'styled-components'
//import { Link as GatsbyLink } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import {GlobalColors} from "../../styles/GlobalStyles";


export const GlobalStyleLinkItemMenu = createGlobalStyle`
  .primary-item{
    color: ${GlobalColors.colorPrimary};
  
    &:hover{
      color: ${GlobalColors.colorLinkHover};
    }
    &[aria-current]{
      color: ${GlobalColors.colorLinkHover};
    }
  }
  .secondary-item{
    color: ${GlobalColors.colorLight};
  
    &:hover{
      color: ${GlobalColors.colorTertiary};
    }
    &[aria-current]{
      color: ${GlobalColors.colorTertiary};
    }
  }
  .tertiary-item{
    color: ${GlobalColors.colorLink};
  
    &:hover{
      color: ${GlobalColors.colorLink};
    }
    &[aria-current]{
      color: ${GlobalColors.colorTertiary};
    }
  }
`

/*export const LinkGatsby = styled(GatsbyLink)`*/
export const LinkGatsby = styled(AnchorLink)`
  /* Display & Box Model */
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  height: 100%;
  padding: 20px 5px;

  /* Typografhy */
  text-decoration: none;
  font: 20px HelveticaNeue;
  
  @media(min-width: 300px) and (max-width: 768px){
    justify-content: flex-start;
    padding: 10px 5px;
    font-size: 20px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
