import React from 'react';
import {
  ContFooter,
  ContLogo,
  FooterDev,
  TextoDev,
  ListSection,
  //LinkSection,
  ContRedes,
} from './styles';
import {Column, Columns, Container} from 'bloomer';
import {LogoSecondary} from '../Logo/logoSecondary';
import {RedesSVG} from '../Redes/redesSVG';

//import IcoPhone from '../../images/ico-phone.svg';
//import IcoPin from '../../images/ico-pin.svg';
import ContactoComp from '../Contacto';
import {LogoDev} from '../Dev';
import {GlobalColors} from '../../styles/GlobalStyles';
import ItemMenuDesk from '../Menu/itemMenuDesk';

const FooterComp = ({data}) => {

  return (
    <ContFooter>
      <ContactoComp/>

      <Container>
        <Columns
          className="columns-responsive columns-margin-side-no full-height"
          isMultiline={true}
        >
          <Column isSize={{mobile: 12, tablet: 8, desktop: 8, widescreen: 8}}>
            <ListSection>
              <li>
                <ItemMenuDesk
                  itemText={'Inicio'}
                  linkTo={'inicio'}
                  className={'secondary-item'}
                />
              </li>
              <li>
                <ItemMenuDesk
                  itemText={'Nosotros'}
                  linkTo={'nosotros'}
                  className={'secondary-item'}
                />
              </li>
              <li>
                <ItemMenuDesk
                  itemText={'Desarrollos'}
                  linkTo={'desarrollos'}
                  className={'secondary-item'}
                />
              </li>
              <li>
                <ItemMenuDesk
                  itemText={'Contacto'}
                  linkTo={'contacto'}
                  className={'secondary-item'}
                />
              </li>
            </ListSection>
          </Column>
          <Column isSize={{mobile: 12, tablet: 4, desktop: 4, widescreen: 4}}>
            <ContRedes>
              <RedesSVG/>
            </ContRedes>
          </Column>
        </Columns>
        <Columns
          className="columns-responsive columns-margin-side-no full-height"
          isMultiline={true}
        >
          <Column isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
            <div>
              <ContLogo>
                <LogoSecondary/>
              </ContLogo>
            </div>
          </Column>
          <Column isSize={{mobile: 0, tablet: 12, desktop: 4, widescreen: 4}} className={'no-display-mobile'}>
            <div>
              {/*<ContContact>
                <IcoContact><img src={IcoPhone} alt={'Phone'}/></IcoContact>
                <TextContact><a href='tel:+529545820489'>954 582 0489</a></TextContact>
              </ContContact>
              <ContContact>
                <IcoContact><img src={IcoPin} alt={'Map'}/></IcoContact>
                <TextContact>
                  <div>2da. Norte, Col. Centro.</div>
                  <div>71980 Puerto Escondido,</div>
                  <div>México</div>
                </TextContact>
              </ContContact>*/}
            </div>
          </Column>
          <Column isSize={{mobile: 0, tablet: 12, desktop: 4, widescreen: 4}} className={'no-display-mobile'}>

          </Column>
        </Columns>
      </Container>
      <FooterDev>
        <Container>
          <Columns className="columns-responsive columns-margin-side-no full-height flex-center">
            <Column isSize={{mobile: 4, tablet: 4, desktop: 4, widescreen: 4}}>
              &nbsp;
            </Column>
            <Column isSize={{mobile: 4, tablet: 4, desktop: 4, widescreen: 4}}>
              <TextoDev>Inmoax 2021</TextoDev>
            </Column>
            <Column isSize={{mobile: 4, tablet: 4, desktop: 4, widescreen: 4}}>
              <LogoDev color={GlobalColors.colorTertiary}/>
            </Column>
          </Columns>
        </Container>
      </FooterDev>
    </ContFooter>
  );
};

export default FooterComp;
