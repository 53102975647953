import styled from 'styled-components';
import {TextArea} from "bloomer";
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../../styles/GlobalStyles";

export const TextAreaRGB = styled(TextArea)`
  height: 215px;
  
  font: 16px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  
  border: none;
  border-radius: 3px;
  border-bottom: 1px solid ${GlobalColors.colorTertiary};
  background-color: ${GlobalBackgroundColors.bgPrimary};
  
  &:focus{
    box-shadow: none;
  }
  &:hover{
    border-bottom: 1px solid ${GlobalColors.colorTertiary};
  }
  
`
