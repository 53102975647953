import * as React from "react"
import PropTypes from "prop-types"
import {GlobalStyleLinkItemMenu, LinkGatsby} from './stylesItemMenuDesk';
import scrollTo from 'gatsby-plugin-smoothscroll';

const ItemMenuDesk = ({itemText, linkTo, className, bolHash = 0, pathname = ''}) => {
  /*
  console.log('itemText => ', itemText);
  console.log('linkTo => ', linkTo);
  */
  const hash = bolHash ? '#' : '';
  let path = '';

  if(pathname !== undefined && pathname !== '' ){
    //path = (pathname);
    //console.log('path -> ', path);
    path = pathname.slice(1, (pathname.length - 0));
    console.log('path slice -> ', path);
    console.log('-------------');
    console.log('linkTo -> ', linkTo);
    console.log('location.pathname -> ', pathname);
    console.log('path menos -> ', path);
    console.log('++++++++');
  }

  const fnGoToElement = (el) => {
    console.log('el =>', el);
    setTimeout(function(){
      scrollTo(`#${el}`);
    }, 50);
  }

  return (
    <LinkGatsby
      to={`/${path}${hash}${linkTo}`}
      onClick={() => fnGoToElement(linkTo)}
      className={className}
    >
      <GlobalStyleLinkItemMenu/>
      {itemText}
    </LinkGatsby>
  )
}

ItemMenuDesk.propTypes = {
  itemText: PropTypes.node.isRequired,
  linkTo: PropTypes.node.isRequired
}

export default ItemMenuDesk
